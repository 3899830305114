import { useState, useEffect } from 'react';
import { proxyUrl } from '../../util/AppSettings';

const HealthCheck = () => {
    const [res, setRes] = useState<string>('Loading...');

    useEffect(() => {
        const fetchHealth = async () => {
            try {
                const response = await fetch(`/api/v1/health/liveness`);
                const data = await response.text();
                setRes(data || 'Unknown status');
            } catch (error) {
                setRes(`Error fetching health status; ${error as Error}`);
            }
        };

        fetchHealth();
    }, []);

    return (
        <h1>{res}</h1>
    );
};

export default HealthCheck;
